<template>
  <div>

      <v-row class="mx-sm-8 mx-2">
        <v-col cols="12">
          <h1 class="mt-10">Documentos Listado</h1>
          <hr class="">
        </v-col>
        <v-col cols="3" sm="3" md="3" class="mt-10  ">
          <v-text-field v-model="searchData" label="Buscar"></v-text-field>
        </v-col>
        <v-col cols="6" class="mt-10  ">
        </v-col>

        <v-col cols="3" class="mt-10 ">
          <v-btn color="primary" to="/nuevo_layout" style="width: 100%">
            Crear nuevo
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="formats"
              :items-per-page="5"
              class="elevation-2"
          >
            <template v-slot:item.action="{ item }">
              <router-link :to="{ name: 'DetailLayout', params: { id: item.id } }">
                <v-icon medium color="black">mdi-magnify</v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  </div>

</template>

<script>


import {mapActions} from "vuex";

export default {
  name: "Formats",
  data() {
    return {
      headers: [
        {text: 'id', value: 'id'},
        {text: 'Nombre', value: 'nombre'},
        {text: 'Orden', value: 'orden'},
        {text: 'Acciones', value: 'action', sortable: false},
      ],
      formats: [

      ],
      searchData: null
    };

  },
  async created(){
    await this.findAll();

  },
  methods: {
    ...mapActions('formats',['findAllFormats']),
    async findAll(){
      const response = await this.findAllFormats();
      if (response.length > 0){
        this.formats = response;
        return
      }
      this.formats =[];
    },

    download(item) {
    },
    search(item) {
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}
</style>
